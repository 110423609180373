import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue';
import ModalDialog from '@/components/common/ModalDialog'
import FetchJson from '@/components/common/FetchJson'
import NoDataPlaceHolder from "@/components/common/NoDataPlaceHolder";

Vue.use(Vuelidate);
Vue.use(PortalVue);

Vue.component('ModalDialog', ModalDialog);
Vue.component('NoDataPlaceHolder', NoDataPlaceHolder);
Vue.component('FetchJson', FetchJson);

Vue.filter("tryToLocaleDateTime", value => {
	if (!value) return "";
	var regexp = new RegExp("(19|20)[0-9][0-9]-(0[0-9]|1[0-2])-(0[1-9]|([12][0-9]|3[01]))T([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]");
	if (!regexp.exec(value)) return value;
	const date = new Date(value);
	if (date instanceof Date && !isNaN(date)) {
		return date.toLocaleDateString() +' ' + date.toLocaleTimeString();
	}
	return value;
});

// Require the main Sass manifest file
require('./assets/css/main.scss');

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');

<template>
    <portal to="modal" v-if="show">
        <div class="modal" :class="{'is-active': show}">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title"><slot name="title"></slot></p>
                    <button class="delete is-large" aria-label="close" @click="close"></button>
                </header>
                <section class="modal-card-body">
                    <slot></slot>
                </section>
                <footer class="modal-card-foot" style="justify-content: flex-end;">
                    <slot name="footer"></slot>
                </footer>
            </div>
        </div>
    </portal>
</template>

<script>

  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      close() {
        this.$emit('close');
      },
      cancel() {
        this.close();
      }
    },
    created() {
      const escapeHandler =
        (e) => {
          if (this.show && e.keyCode == 27) {
            this.close();
          }
        };
      document.addEventListener('keydown', escapeHandler);

      this.$once('hook:beforeDestroye', () => {
        document.removeEventListener('keydown', escapeHandler);
      });
    },
  }
</script>
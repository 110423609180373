<template>
	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<p class="has-text-grey is-pulled-left">Имя портала</p>
		</div>
		<div class="field-body">
			<div class="field">
				<p class="control">
					<input class="input" type="text" v-model="value">
				</p>
				<p v-if="error" class="help is-danger">{{error}}</p>
			</div>
		</div>		
	</div>
</template>

<script>
	import AgateSettingsApi from '../../api/agateSettingsApi';
	import _debounce from 'lodash.debounce'
	export default {
		props: ['name'],
		data() {
			return {
				value: null,
				delay: 500,
				error:null
			}
		},			
		created() {
			this.value = this.name;
			const debouncedValueText = _debounce(() => {
				this.error = null;
				AgateSettingsApi.saveName(this.value)
					.catch(() =>this.error = 'Ошибка сохранения имени портала');
			}, this.delay);
			this.$watch('value', debouncedValueText);
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

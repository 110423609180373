<template>
  <div class="home">
    <div class="columns">
      <div class="column">
        <named-group name="Верхнее меню портала">
          <FetchJson url="settings" :key="settingsKey">
            <template slot-scope="{ json: settings, loading }">
              <div v-if="loading">Загрузка...</div>
              <div v-else>
                <NameField :name="settings.portalName"></NameField>
                <LogoField
                  :logo="settings.logo"
                  @change="reloadSettingsProjects"
                ></LogoField>
              </div>
            </template>
          </FetchJson>
        </named-group>
        <named-group name="Проекты">
          <AgateProjects
            :key="agateProjectsKey"
            @gems-agate-projects-updated="reloadAgateProjects"
            @gems-agate-project-removed="reloadAgateProjects"
          >
            <template v-slot:toolpanel="{ projects }">
              <p class="buttons">
                <AddIasProjectsButton
                  @gems-ias-projects-added="reloadAgateProjects"
                ></AddIasProjectsButton>
                <UpdateAgateProjectButton
                  :projects="projects"
                  title="Обновить данные проектов"
                  @gems-agate-projects-updated="reloadAgateProjects"
                >
                  Обновить проекты
                </UpdateAgateProjectButton>
              </p>
            </template>
          </AgateProjects>
        </named-group>
      </div>
      <!--			<div class="column  is-one-fifth">-->
      <!--				<named-group name="Подложки">-->
      <!--					<AddBackgroundLayer :backgrounds="backgrounds"></AddBackgroundLayer>-->
      <!--				</named-group>-->
      <!--			</div>-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NamedGroup from "@/components/common/NamedGroup";
import AddIasProjectsButton from "@/components/projects/AddIasProjectsButton";
import UpdateAgateProjectButton from "../components/projects/UpdateAgateProjectButton";
import AgateProjects from "@/components/projects/AgateProjects";
// import AddBackgroundLayer from '@/components/backgrounds/AddBackgroundLayer'
import NameField from "@/components/settings/NameField";
import LogoField from "@/components/settings/LogoField";

import AgateProjectsApi from "../api/agateProjectsApi";

export default {
  name: "home",
  components: {
    NamedGroup,
    AddIasProjectsButton,
    UpdateAgateProjectButton,
    AgateProjects,
    // AddBackgroundLayer,
    LogoField,
    NameField,
  },
  data() {
    return {
      projects: [],
      agateProjectsKey: Date.now(),
      backgrounds: [{}, {}, {}],
      settingsKey: Date.now(),
    };
  },
  methods: {
    reloadAgateProjects() {
      this.agateProjectsKey = Date.now();
    },
    reloadSettingsProjects() {
      this.settingsKey = Date.now();
    },
  },
  async beforeRouteEnter(to, from, next) {
    const agateProjects = await AgateProjectsApi.get();
    if (agateProjects.length > 0) {
      next();
    } else next("/init");
  },
};
</script>


<script>
	import PublishApi from '../../api/publishApi';
	export default {
		props: ["projects"],
		methods: {
			add() {
				const addProjects = function (projects) {
					if (projects.length === 0)
						return Promise.resolve(projects);
					return PublishApi
						.publish(projects.map(p => p.schema))
						.then(() => {
							this.$emit('gems-ias-projects-added', projects);
						});
				};
				return addProjects.bind(this);
			},
		},
		render() {
			return this.$scopedSlots.default({
				add: this.add()
			});
		}
	}

</script>


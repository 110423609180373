<template>
  <tr>
    <td>
      <a @click="showDialog = true">
        {{ project.name }}
        <EditProjectDialog
          :project="project"
          :show="showDialog"
          @close="showDialog = false"
        ></EditProjectDialog>
      </a>
    </td>
    <td>
      <AgateProjectStatus :project="project"></AgateProjectStatus>
    </td>
    <td>
      <AgateProjectTools
        :project="project"
        @gems-agate-projects-updated="$emit('gems-agate-projects-updated')"
        @gems-agate-project-removed="$emit('gems-agate-project-removed')"
      ></AgateProjectTools>
    </td>
  </tr>
</template>

<script>
import AgateProjectStatus from "./AgateProjectStatus";
import AgateProjectTools from "./AgateProjectTools";
import EditProjectDialog from "../project/EditProjectDialog";

export default {
  props: {
    project: {},
  },
  data() {
    return {
      showDialog: false,
    };
  },
  components: {
    AgateProjectStatus,
    AgateProjectTools,
    EditProjectDialog,
  },
};
</script>
<style>
.table tr:hover .buttons-group {
  visibility: visible;
}
</style>
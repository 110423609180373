import Api from "./api";

class GeoJsonApi {
	static isValid = (geoJson) => {
		const url = `geojson/isvalid`;
		return Api
			//.post(url, `"${geoJson}"`)
			.post(url, { value: geoJson})
			.then(response => {
				return response.data;
			});
	}
}

export default GeoJsonApi;
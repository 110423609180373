<template>
	<ul class="projects">
		<li v-for="project in projects" :key="project.schema">
			<div class="field">
				<input class="is-checkradio is-info" :class="{'has-background-color':isSelected(project)}" :id="project.schema" type="checkbox" :value="project" v-model="selectedProjects">
				<label :for="project.schema">{{project.name}}</label>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			projects: { required: true, type: Array },
			value: { required: true, type: Array }
		},
		data() {
			return {
				selectedProjects: []
			}
		},
		methods: {
			isSelected(prj) {
				return this.value.indexOf(prj) > -1;				
			},
		},
		watch: {
			selectedProjects(newVal) {
				this.$emit('input', newVal);
			}
		}
	}
</script>
<style scoped >
	.field {
		margin-bottom: 12px;
	}

	.projects li {
		margin-bottom: 0.8rem;
		margin-right: 2rem;
	}
</style>

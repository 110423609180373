<script>
	import Api from "@/api/api"
	export default {
		props: {
			url: {
				type: String,
				required: true
			},
			params: { default: null }
		},
		data() {
			return {
				json: null,
				loading: false
			}
		},
		methods: {
			fetch() {
				this.reset();

				Api.get(this.url, { params: this.params })
					.then(response => {
						this.json = response.data;
					})
					.finally(() => {
						this.loading = false;
					});
			},
			reset() {
				this.loading = true;
				this.error = null;
			}
		},
		created() {
			if (this.url) this.fetch();
		},
		watch: {
			url() { this.fetch(); },
			params() { this.fetch(); }
		},
		render() {
			return this.$scopedSlots.default({
				json: this.json,
				loading: this.loading
			});
		}
	}
</script>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcUser"]),
  },
  methods: {
    ...mapActions("oidcStore", ["signOutOidc"]),
  },
  render() {
    return this.$scopedSlots.default({
      isAuthenticated: this.oidcIsAuthenticated,
      user: this.oidcIsAuthenticated ? this.oidcUser : null,
      signOut: this.signOutOidc,
    });
  },
};
</script>
<template>
	<div class="field is-horizontal">
		<div class="field-label is-normal">
			<p class="has-text-grey is-pulled-left">Изображение-логотип</p>
		</div>
		<div class="field-body">
			<div class="field">
				<LogoInput :logo="logo" @change="handleFileUpload"></LogoInput>
			</div>
		</div>
	</div>
</template>

<script>
	import LogoInput from '@/components/settings/LogoInput'
	import AgateSettingsApi from '../../api/agateSettingsApi';

	export default {
		props:["logo"],
		components: {
			LogoInput
		},
		methods: {
			handleFileUpload(file) {				
				const formData = new FormData();
				formData.append('logo', file);
				AgateSettingsApi.saveLogo(formData)
					.then(()=>this.$emit('change'));
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

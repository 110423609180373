//export const oidcSettings = JSON.parse(process.env.VUE_APP_OIDC_CONFIG)

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : window.location.origin;

const baseAuthorityUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5002"
    : window.location.origin + "/idsrv";

export const oidcSettings = {
  authority: baseAuthorityUrl,
  client_id: "agate.configurator",
  redirect_uri: baseUrl + "/oidc-callback",
  response_type: "code",
  scope: "openid profile api1",
  post_logout_redirect_uri: baseUrl,
  silent_redirect_uri: baseUrl + "/silent-renew-oidc.html",
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  silentRequestTimeout: 10000,
  monitorSession: true,
};

<script>
	const NormalStatus = {
		functional: true,
		props: ['project'],
		render: function (createElement, data) {
			return createElement('span', data.parent.$options.filters["tryToLocaleDateTime"](data.parent.project.publishDate))
		}
	}
	const AddingStatus = {
		functional: true,
		render: function (createElement) {
			return createElement('span', { attrs: { class: 'has-text-grey' } }, 'добавляется...');
		}
	}
	const UpdatingStatus = {
		functional: true,
		render: function (createElement) {
			return createElement('span', { attrs: { class: 'has-text-grey' } }, 'обновляется...');
		}
	}
	export default {

		functional: true,
		props: {
			project: {
				type: Object,
				required: true
			}
		},

		render: function (createElement, context) {
			function appropriateStatusComponent() {
				const status = context.props.project.status;
				switch (status) {
					case 'Normal':						
						return NormalStatus;
					case 'Adding':
						return AddingStatus;
					case 'Updating':
						return UpdatingStatus;
					default:
						throw new Error('Неизвестный статус проекта.')
				}
			}

			return createElement(
				appropriateStatusComponent(),
				context.data,
				context.children
			)
		}
	}
</script>

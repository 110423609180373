<template>
	<button class="button is-link" :title="title" @click="update(projects)" >
		<span class="icon">
			<slot name="image"><img src="../../assets/svg/refresh.svg" /></slot>
		</span>
		<span><slot/></span>
	</button>	
</template>

<script>
	import PublishApi from '../../api/publishApi';

	export default {
		props: {
			projects: {
				type: Array,
				required: true
			},
			title: {
				type: String
			}
		},		
		methods: {
			update(projects) {
				PublishApi
					.update(projects.map(p => p.schema))
					.then(() => {
						this.$emit('gems-agate-projects-updated', projects);
					});
			}
		}
	}
</script>


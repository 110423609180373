import Api from "./api";

class PublishApi {
	static publish = (projectSchemas) => {
		const url = `publish`;
		return Api
			.post(url, projectSchemas)
			.then(response => {
				return response.data;
			});
	};

	static getStatus = () => {
		const url = `publish/status`;
		return Api
			.get(url)
			.then(response => {
				return response.data;
			});
	};

	static update = (projectSchemas) => {
		const url = `update`;
		return Api
			.post(url, projectSchemas)
			.then(response => {
				return response.data;
			});
	};
}

export default PublishApi;
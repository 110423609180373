<template>
	<div id="app">
		<navbar></navbar>
		<router-view />
		<portal-target name="modal"></portal-target>
	</div>
</template>
<script>
	import Navbar from './components/navbar/Navbar'
	export default {
		props: {
			projectSchema: { type: String }
		},
		components: {
			Navbar
		}		
	}
</script>
<style lang="scss">
	@import './assets/css/main.scss';

	#app {
		font-family: 'Roboto', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100vh;
		/*background-color: $white-ter;*/
	}
</style>

<template>
  <div v-if="hasTools" class="buttons buttons-group">
    <UpdateAgateProjectButton
      class="is-outlined"
      :projects="[project]"
      title="Обновить данные этого проекта"
      @gems-agate-projects-updated="$emit('gems-agate-projects-updated')"
    >
      <img slot="image" src="../../assets/svg/refresh_blue.svg" />
      Обновить данные
    </UpdateAgateProjectButton>
    <RemoveAgateProjectButton
      :project="project"
      title="Удалить публикацию проекта с портала"
      @gems-agate-project-removed="$emit('gems-agate-project-removed')"
    >
      Удалить проект</RemoveAgateProjectButton
    >
  </div>
</template>
<script>
import UpdateAgateProjectButton from "./UpdateAgateProjectButton";
import RemoveAgateProjectButton from "./RemoveAgateProjectButton";
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  components: {
    UpdateAgateProjectButton,
    RemoveAgateProjectButton,
  },
  computed: {
    hasTools() {
      return this.project.status === "Normal";
    },
  },
};
</script>

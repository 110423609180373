<template>
	<modal-dialog :show="show" @close="cancel">
		<template slot="title">
			<p class="title is-4">Добавление проекта</p>
			<p class="subtitle modal-subtitle is-6">Выберите проекты для публикации на портале</p>
		</template>
		<StackedIasProjectsInput :projects="projects" v-model="selectedProjects"></StackedIasProjectsInput>		

		<template slot="footer">			
			<button class="button is-link" @click="ok()">Добавить</button>
			<button class="button is-text" @click="cancel()">Отмена</button>
		</template>
	</modal-dialog>
</template>

<script>	
	import StackedIasProjectsInput from '../../components/projects/StackedIasProjectsInput';

	export default {
		props: {
			projects: {
				type:Array
			},
			show: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {				
				selectedProjects: []
			}
		},
		components: {			
			StackedIasProjectsInput
		},
		methods: {
			closeModal() {
				this.$emit('close');
			},
			cancel() {
				this.closeModal();
			},
			ok() {
				this.$emit('gems-ias-projects-selected', this.selectedProjects);
				this.closeModal();
			}
		}		
	}
</script>

<style>
	.modal-subtitle {
		padding-top: 10px;
	}
</style>

<template>
	<div>
		<h1>Ошибка аутентификации</h1>
	</div>
</template>
<script>
	export default {
		name: 'OidcCallbackError'
	}
</script>

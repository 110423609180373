<template>
  <button
    class="button is-link"
    :title="title"
    @click="showConfirmDialig = true"
    :disabled="disabled"
  >
    <span><slot /></span>
    <RemoveAgateProjectConfirmDialog
      :show="showConfirmDialig"
      :project="project"
      @close="showConfirmDialig = false"
      @gems-ias-project-delete-confirmed="remove"
    />
  </button>
</template>

<script>
import AgateProjectsApi from "../../api/agateProjectsApi";
import RemoveAgateProjectConfirmDialog from "./RemoveAgateProjectConfirmDialog";

export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },
  components: {
    RemoveAgateProjectConfirmDialog,
  },
  data() {
    return {
      disabled: false,
      showConfirmDialig: false,
    };
  },
  methods: {
    remove(project) {
      this.disabled = true;
      AgateProjectsApi.remove(project.schema).then(() => {
        this.disabled = false;
        this.$emit("gems-agate-project-removed", project);
      });
    },
  },
};
</script>


<template>
    <fieldset class="named-group">
        <legend v-html="spacedName"></legend>
        <slot></slot>
    </fieldset>
</template>

<script>

    export default {
        props: {
            name: {
                required: false
            }
        },
        computed: {
            spacedName() {
                return this.name + '&nbsp;';
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '../../assets/css/main.scss';

    .named-group {
        border-top: 1px $grey-lighter solid;
        margin: 40px 50px;
        padding-top: 13px;
    }
</style>

<template>
  <div>
    <div class="pageloader is-active has-background-grey">
      <span class="title">Пожалуйста, подождите...</span>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "OidcCallback",
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((err) => {
        window.console.error(err);
        this.$router.push("/oidc-callback-error"); // Handle errors any way you want
      });
  },
};
</script>
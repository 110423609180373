<template>
	<modal-dialog :show="show" @close="cancel">
		<template slot="title">
			<p class="title is-4">{{project.name}}</p>
		</template>
		<form class="form" @submit.prevent="ok">
			<div class="field">
				<label class="label">Имя проекта для отображения на портале</label>
				<div class="control">
					<input class="input" type="text" v-model.trim="settings.displayName" :class="{'is-danger':$v.settings.displayName.$error}" @blur="$v.settings.displayName.$touch()">
				</div>
				<p class="help is-danger" v-if="$v.settings.displayName.$error">Поле обязательное для заполнения</p>
			</div>
			<div class="field">
				<label class="label">Геометрия границы муниципального образования</label>
				<div class="control">
					<textarea class="textarea" :value="settings.boundsGeometryJsonString" @input="inputGeometryJson($event.target.value)"></textarea>
				</div>
				<p class="help is-danger" v-if="!$v.settings.boundsGeometryJsonString.isValid">Синтаксическая ошибка в коде</p>
			</div>

			<!--			<div class="field">-->
			<!--				<label class="label">Подложки, отображаемые на карте</label>-->
			<!--				<div class="control">-->
			<!--					<BackgroundLayerList></BackgroundLayerList>-->
			<!--				</div>				-->
			<!--			</div>-->

		</form>
		<template slot="footer">
			<button class="button is-link" @click="ok()">Сохранить</button>
			<button class="button is-text" @click="cancel()">Отмена</button>
		</template>
	</modal-dialog>
</template>

<script>
	import { required } from 'vuelidate/lib/validators'
	import _debounce from 'lodash.debounce'
	import AgateProjectApi from "../../api/agateProjectsApi";
	import GeoJsonApi from "../../api/geoJsonApi";
	// import BackgroundLayerList from '@/components/project/BackgroundLayerList'
	export default {
		props: {
			project: { type: Object },
			show: {
				type: Boolean,
				default: false
			}
		},
		// components: {
		// 	BackgroundLayerList
		// },
		data() {
			return {
				delay: 500,
				settings: {
					displayName: null,
					boundsGeometryJsonString: null
				}
			};
		},
		validations: {
			settings: {
				displayName: { required },
				boundsGeometryJsonString: {
					async isValid(value) {
						if (!value)
							return true;
						const response = await GeoJsonApi.isValid(value);
						return Boolean(response);
					}
				}
			}
		},
		computed: {
			isFormValid() {
				return !this.$v.$invalid
			}
		},
		methods: {
			closeModal() {
				this.$emit('close');
			},
			cancel() {
				this.closeModal();
			},
			ok() {
				if (this.isFormValid) {
					AgateProjectApi.updateSettings(this.project.schema, this.settings).then(() => {
						this.project.name = this.settings.displayName;
						this.$emit('gems-projectsettings-saved', this.settings);
						this.closeModal();
					});
				}
			},
			inputGeometryJson(value) {
				this.debouncedGeometryJsonText(value);
			}
		},
		watch: {
			show: async function watchShow (val) {
				if (val === true)
					await AgateProjectApi.getSettings(this.project.schema).then(settings => this.settings = settings);
			},
		},
		created() {			
			this.debouncedGeometryJsonText = _debounce((value) => {
				this.settings.boundsGeometryJsonString = value;
				this.$v.settings.boundsGeometryJsonString.$touch();
			}, this.delay);
		},
	}
</script>

<style scoped>
	.textarea {
		resize: none;
	}
</style>

<template>
  <AuthUser>
    <template slot-scope="{ user, signOut }">
      <div id="navbarBasic" class="navbar-menu" ref="menu">
        <div class="navbar-end">
          <div class="navbar-item text-version">Версия 0.1</div>
          <a class="navbar-item" @click="signOut">
            <span class="user-name">{{ user ? user.name : "" }}</span>
            <img src="../../assets/svg/person.svg" width="16" height="15" />
          </a>
        </div>
      </div>
    </template>
  </AuthUser>
</template>

<script>
import AuthUser from "@/components/auth/AuthUser";
export default {
  components: {
    AuthUser,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/css/main.scss";
.text-version {
  color: $cyan !important;
  font-size: 14px;
}
.user-name {
  padding-right: 10px;
}
</style>

import Api from './api';

class IasProjectsApi {
  static get = () => {
    const url = `iasprojects`;
    return Api.get(url).then((response) => {
      return response.data;
    });
  };
}

export default IasProjectsApi;

<template>
  <modal-dialog :show="show" @close="cancel">
    <template slot="title">
      <p class="title is-4">Удаление публикации проекта</p>
    </template>
    <p>Хотите удалить публикацию проекта на портале?</p>
    <template slot="footer">
      <button class="button is-link" @click="ok()">Ок</button>
      <button class="button is-text" @click="cancel()">Отмена</button>
    </template>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close");
    },
    cancel() {
      this.closeModal();
    },
    ok() {
      this.$emit("gems-ias-project-delete-confirmed", this.project);
      this.closeModal();
    },
  },
};
</script>
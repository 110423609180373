import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from '@/store';

import Home from '../views/Home';
import NotFoundPage from '../views/NotFoundPage';
import NotAuthtorizationPage from '../views/NotAuthtorizationPage';
import OidcCallback from '@/views/OidcCallback';
import OidcCallbackError from '@/views/OidcCallbackError';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/init',
    name: 'init',
    component: () => import(/* webpackChunkName: "init" */ '../views/Init'),
  },
  {
    path: '/about',
    name: 'about',

    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/403',
    component: NotAuthtorizationPage,
    meta: {
      isPublic: true,
    },
  },

  {
    path: '/404',
    component: NotFoundPage,
    meta: {
      isPublic: true,
    },
  },

  {
    path: '/:catchAll(.*)',
    component: NotFoundPage,
    meta: {
      isPublic: true,
    },
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;

import axios from 'axios';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  baseURL: window.location.origin + '/api/v1',
  headers: {
    'Content-Type': 'application/json, text/plain, */*',
    Accepts: 'application/json',
  },
});
instance.interceptors.request.use(
  function(config) {
    config.headers.common['Authorization'] =
      'Bearer ' + store.state.oidcStore.access_token;
    return config;
  },
  function(error) {
    if (error.response) {
      //emitNotification({ title: 'Ошибка', message: error.response.data });
      window.console.log(error.response.data);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 403) {
      store.dispatch('logout');
      router.push('/403');
    }

    return Promise.reject(error);
  }
);

export default instance;

<template>
  <nav class="navbar is-link" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link :to="{ name: 'home' }" class="navbar-item" exact>
        <img src="../../assets/svg/logo.svg" alt="Портал ГИСОГД" />
        <span class="navbar-brand-name"> Конфигуратор портала ГИСОГД </span>
      </router-link>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasic"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <NavbarSignedIn />
  </nav>
</template>

<script>
import NavbarSignedIn from "./NavbarSignedIn.vue";
export default {
  components: { NavbarSignedIn },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar-brand-name {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  padding-left: 10px;
}
</style>

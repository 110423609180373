import Api from "./api";

class AgateSettingsApi {

	static get = () => {
		const url = `settings`;
		return Api
			.get(url)
			.then(response => {
				return response.data;
			});
	};

	static saveName = (name) => {
		const url = `settings`;
		return Api
			.put(`${url}/name`,`"${name}"`)
			.then(response => {
				return response.data;
			});
	};

	static saveLogo = (formData) => {
		const url = `settings`;
		return Api
			.post(`${url}/logo`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				return response.data;
			});
	};
}

export default AgateSettingsApi;
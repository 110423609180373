<template>
  <div class="is-flex field-logo">
    <figure class="image is-48x48 control" @click="logoClick">
      <img v-if="logo" :src="srcBase64" />
      <img v-else src="../../assets/svg/green-logo.svg" />
    </figure>
    <span class="field-logo-label">
      {{ fileName }}
    </span>
    <input
      class="is-hidden"
      type="file"
      ref="inputLogo"
      @change="handleFileChange"
      accept="image/jpeg,image/png,image/gif,image/svg+xml"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileName: "",
    };
  },
  props: ["logo"],
  computed: {
    srcBase64() {
      return `data:${this.logo.fileType};base64,${this.logo.content}`;
    },
  },
  methods: {
    logoClick() {
      this.$refs.inputLogo.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.$emit("change", file);
    },
  },
  created() {
    if (this.logo) this.fileName = this.logo.fileName;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.field-logo {
  flex-direction: row;
  align-items: center;
}

.field-logo .image {
  cursor: pointer;
}

.field-logo-label {
  padding-left: 1rem;
}
</style>

<template>
	<modal-dialog :show="show" @close="cancel">
		<template slot="title">
			<p class="title is-4">Добавление проекта</p>
			<p class="subtitle modal-subtitle is-6">Все проекты уже размещены на портале ГИСОГД</p>
		</template>

		<template slot="footer">
			<button class="button is-text" @click="closeModal">Закрыть</button>
		</template>
	</modal-dialog>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			closeModal() {
				this.$emit('close');
			},
			cancel() {
				this.closeModal();
			},
		}
	}
</script>

<style>
	.modal-subtitle {
		padding-top: 10px;
	}
</style>

<template>
  <fetch-json url="agateprojects">
    <template slot-scope="{ json: projects, loading }">
      <div v-if="loading">Загрузка...</div>
      <template v-else>
        <template v-if="projects && projects.length">
          <div>
            <slot name="toolpanel" :projects="projects"></slot>
            <table class="table is-fullwidth is-striped">
              <thead>
                <tr>
                  <th>Имя проекта</th>
                  <th>Последнее обновление данных</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="project in projects"
                  :key="project.schema"
                  :project="project"
                  is="AgateProject"
                  @gems-agate-projects-updated="
                    $emit('gems-agate-projects-updated')
                  "
                  @gems-agate-project-removed="
                    $emit('gems-agate-project-removed')
                  "
                ></tr>
              </tbody>
            </table>
          </div>
        </template>
        <no-data-place-holder v-else></no-data-place-holder>
      </template>
    </template>
  </fetch-json>
</template>

<script>
import AgateProject from "./AgateProject";

export default {
  components: {
    AgateProject,
  },
};
</script>
<style>
.buttons-group {
  visibility: hidden;
}

.table tr:hover .buttons-group {
  visibility: visible;
}
</style>
<template>
	<RenderlessAddIasProjectButton @gems-ias-projects-added="added">
		<button class="button is-link" @click="loadProjects" slot-scope="{add}">
			<span class="icon">
				<img src="../../assets/svg/plus.svg" />
			</span>
			<span>Добавить проект</span>
			<AddIasProjectsDialog :show="showDialog==showDialogState.ADD_PROJECTS" :projects="projects" @close="showDialog=showDialogState.NONE" @gems-ias-projects-selected="add"></AddIasProjectsDialog>
			<NoIasProjectsDialog :show="showDialog==showDialogState.NO_PROJECTS" @close="showDialog=showDialogState.NONE"></NoIasProjectsDialog>			
		</button>
	</RenderlessAddIasProjectButton>
</template>

<script>
	import IasProjectsApi from '../../api/iasProjectsApi';

	import AddIasProjectsDialog from './AddIasProjectsDialog'
	import NoIasProjectsDialog from './NoIasProjectsDialog'	
	import RenderlessAddIasProjectButton from './RenderlessAddIasProjectButton'

	const ShowDialogState = {
		NONE: 0,
		ADD_PROJECTS: 1,
		NO_PROJECTS: 2,
	};

	export default {
		data() {
			return {
				projects: [],				
				showDialogState:ShowDialogState,
				showDialog: ShowDialogState.NONE
			}
		},
		components: {
			AddIasProjectsDialog,
			NoIasProjectsDialog,			
			RenderlessAddIasProjectButton
		},		
		methods: {
			async loadProjects() {
				this.showDialog = this.showDialogState.NONE;
				this.projects = await IasProjectsApi.get();
				this.showDialog = (this.projects.length > 0) ? this.showDialogState.ADD_PROJECTS : this.showDialogState.NO_PROJECTS;
			},
			added(projects) {
				this.showDialog = this.showDialogState.NONE;
				this.$emit('gems-ias-projects-added', projects);
			}
		}
	}	
</script>
import Api from './api';

class AgateProjectsApi {
  static get = () => {
    const url = `agateprojects`;
    return Api.get(url).then((response) => {
      return response.data;
    });
  };

  static getSettings = (projectSchema) => {
    const url = `agateprojects/settings/${projectSchema}`;
    return Api.get(url).then((response) => {
      return response.data;
    });
  };

  static updateSettings = (projectSchema, value) => {
    const url = `agateprojects/settings/${projectSchema}`;
    return Api.put(url, value).then((response) => {
      return response.data;
    });
  };

  static remove = (projectSchema) => {
    const url = `agateprojects/${projectSchema}`;
    return Api.delete(url).then((response) => {
      return response.data;
    });
  };
}

export default AgateProjectsApi;

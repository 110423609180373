import Vue from "vue";
import Vuex from "vuex";
import {
  vuexOidcCreateStoreModule,
  vuexOidcCreateUserManager,
} from "vuex-oidc";
import { oidcSettings } from "@/oidc/config";

Vue.use(Vuex);
let userManager = vuexOidcCreateUserManager(oidcSettings);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      // Optional OIDC store settings
      {
        namespaced: true,
        dispatchEventsOnWindow: true,
      },
      // Optional OIDC event listeners
      {
        userLoaded: () => {
          window.console.log("OIDC user is loaded");
        },
        userUnloaded: () => window.console.log("OIDC user is unloaded"),
        accessTokenExpiring: () =>
          window.console.log("Access token will expire"),
        accessTokenExpired: () => {
          window.console.log("Access token did expire");
          userManager.signoutRedirect();
        },
        silentRenewError: () => window.console.log("OIDC user is unloaded"),
        userSignedOut: () => {
          window.console.log("OIDC user is signed out");
          userManager.signoutRedirect();
        },
      }
    ),
  },
});
